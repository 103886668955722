<template>
  <div ref="root" class="flickity">
    <slot></slot>
    <template v-if="nav">
      <template v-for="(image, index) in images" :key="index">
        <div class="col-12 px-2" style="max-width: 113px">
          <div
            class="embed-responsive embed-responsive-1by1 bg-cover"
            :style="{ backgroundImage: 'url(' + image.thumbnail + ')' }"
          ></div>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-for="(image, index) in images" :key="index">
        <img :src="image.image" class="card-img-top" />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, onUpdated, ref } from 'vue';
import Flickity from 'flickity';
import 'flickity-as-nav-for';

export default defineComponent({
  props: {
    options: Object,
    images: Array,
    nav: Boolean,
  },
  setup(props) {
    const flickity = ref<typeof Flickity | null>(null);
    const root = ref<HTMLElement | null>(null);

    function initialize() {
      flickity.value = new Flickity(root.value as HTMLElement, props.options);
    }

    function reload() {
      if (flickity.value !== null) {
        flickity.value.destroy();
        initialize();
      }
    }

    function append(element: HTMLElement) {
      if (flickity.value !== null) {
        flickity.value.append(element);
        flickity.value.select(-1);
      }
    }

    function clear() {
      if (flickity.value !== null) {
        flickity.value.destroy();
        flickity.value = null;
      }
    }

    /*function select(index: number, isWrapped = false, isInstant = false): void {
      if (flickity.value !== null) {
        flickity.value.select(index, isWrapped, isInstant);
      }
    }

    function previous(isWrapped: boolean, isInstant: boolean) {
      if (flickity.value !== null) {
        flickity.previous(isWrapped, isInstant);
      }
    }

    function next(isWrapped: boolean, isInstant: boolean) {
      if (flickity.value !== null) {
        flickity.value.next(isWrapped, isInstant);
      }
    }

    function selectCell(value: number, isWrapped: boolean, isInstant: boolean) {
      if (flickity.value !== null) {
        flickity.value.selectCell(value, isWrapped, isInstant);
      }
    }

    function resize() {
      if (flickity.value !== null) {
        flickity.value.resize();
      }
    }

    function reposition() {
      if (flickity.value !== null) {
        flickity.value.reposition();
      }
    }

    function prepend(element: HTMLElement) {
      if (flickity.value !== null) {
        flickity.value.prepend(element);
      }
    }

    function insert(element: HTMLElement, index: number) {
      if (flickity.value !== null) {
        flickity.value.insert(element, index);
      }
    }

    function remove(element: HTMLElement) {
      if (flickity.value !== null) {
        flickity.value.remove(element);
      }
    }

    function playPlayer(): void {
      if (flickity.value !== null) {
        flickity.value.playPlayer();
      }
    }

    function stopPlayer(): void {
      if (flickity.value !== null) {
        flickity.value.stopPlayer();
      }
    }

    function pausePlayer(): void {
      if (flickity.value !== null) {
        flickity.value.pausePlayer();
      }
    }

    function unpausePlayer(): void {
      if (flickity.value !== null) {
        flickity.value.unpausePlayer();
      }
    }

    function rerender(): void {
      if (flickity.value !== null) {
      flickity.value.destroy();
      initialize();
      }
    }

    function destroy() {
      if (flickity.value !== null) {
      flickity.value.destroy();
      }
    }

    function reloadCells() {
      if (flickity.value !== null) {
      flickity.value.reloadCells();
      }
    }

    function getCellElements() {
      if (flickity.value !== null) {
      return flickity.value.getCellElements();
      }
    }

    function data() {
      if (flickity.value !== null) {
      return flickity.value.data(root);
      }
    }

    function on(eventName, listener) {
      if (flickity.value !== null) {
      flickity.value.on(eventName, listener);
      }
    }

    function off(eventName, listener) {
      if (flickity.value !== null) {
      flickity.value.off(eventName, listener);
      }
    }

    function once(eventName, listener) {
      if (flickity.value !== null) {
      flickity.value.once(eventName, listener);
      }
    }

    function selectedElement() {
      if (flickity.value !== null) {
      return flickity.value.selectedElement;
      }
    }

    function selectedElements() {
      if (flickity.value !== null) {
      return flickity.value.selectedElements;
      }
    }

    function selectedIndex() {
      if (flickity.value !== null) {
      return flickity.value.selectedIndex;
      }
    }

    function cells() {
      if (flickity.value !== null) {
      return flickity.value.cells;
      }
    }

    function slides() {
      if (flickity.value !== null) {
      return flickity.value.slides;
      }
    }*/

    onMounted(() => initialize());
    onUnmounted(() => clear());
    onUpdated(() => reload());

    return {
      root,
      flickity,
      append,
      //select,
      //previous,
      //next,
      //resize,
      //selectCell,
      //prepend,
      //reposition,
      //slides,
      //cells,
      //selectedIndex,
      //selectedElements,
      //selectedElement,
      //once,
      //on,
      //off,
      //data,
      //getCellElements,
      //reloadCells,
      //rerender,
      //destroy,
      //playPlayer,
      //stopPlayer,
      //pausePlayer,
      //unpausePlayer,
      //remove,
      //insert,
    };
  },
});
</script>

<style scoped>
@import '~flickity/dist/flickity.css';

.flickity .carousel {
  background: #eee;
  margin-bottom: 40px;
}
.flickity::v-deep .carousel-cell {
  height: 200px;
  width: 25%;
  margin: 0 10px;
  background: #6c6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.flickity-viewport {
  height: 100%;
}
#productSlider {
  height: 650px;
  overflow: hidden;
}
#productSlider img {
  height: 650px;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
}
</style>
