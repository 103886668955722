
import { defineComponent, ref, computed, toRefs, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Flickity from './Flickity.vue';

export default defineComponent({
  name: 'Card',
  components: {
    Flickity,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    variations: {
      type: Array,
      required: false,
    },
  },
  setup(props) {
    const { data } = toRefs(props);

    const router = useRouter();

    const route = useRoute();

    const collapse = ref(false);

    const options = reactive({
      prevNextButtons: false,
      pageDots: false,
      draggable: false,
      imagesLoaded: true,
      setGallerySize: true,
      adaptiveHeight: true,
      initialIndex: 0,
    });

    function goToSlug(slug) {
      router.push({ path: `/products/${slug}` });
    }

    function formatSize(size) {
      if (size.isInteger) {
        return size;
      } else {
        return size[0];
      }
    }

    const sizeAttribute = computed(() => {
      return data.value.attributes.filter((attribute) => attribute.category === 'Size');
    });

    const colorAttribute = computed(() => {
      const color: Array<any> = [];

      //TODO: select radio element that matches the color attribute in data.value.attributes

      /*for (const attribute of data.value.attributes) {
        if (attribute.category === 'Color') {
          color.push(attribute);
        }
      }*/

      for (const alternative of data.value.alternatives) {
        for (const attribute of alternative.attributes) {
          if (attribute.category === 'Color') {
            color.push(attribute);
          }
        }
      }

      return color;
    });

    function enter() {
      collapse.value = true;

      let cardCollapse = document.querySelector('.card-collapse');

      if (cardCollapse) {
        cardCollapse.classList.replace('collapse', 'collapsing');

        let isCollapsing = cardCollapse.classList.contains('collapsing');

        if (isCollapsing) {
          setTimeout(() => {
            if (cardCollapse) {
              cardCollapse.classList.replace('collapsing', 'collapse');
              cardCollapse.classList.add('show');
            }
          }, 100);
        } else {
          cardCollapse.classList.add('show');
        }
      }
    }

    function leave() {
      collapse.value = false;

      let cardCollapse = document.querySelector('.card-collapse');

      if (cardCollapse) {
        cardCollapse.classList.replace('collapse', 'collapsing');

        let isCollapsing = cardCollapse.classList.contains('collapsing');

        if (isCollapsing) {
          setTimeout(() => {
            if (cardCollapse) {
              cardCollapse.classList.replace('collapsing', 'collapse');
              cardCollapse.classList.remove('show');
            }
          }, 350);
        } else {
          cardCollapse.classList.remove('show');
        }
      }
    }

    return {
      collapse,
      sizeAttribute,
      colorAttribute,
      route,
      options,
      goToSlug,
      enter,
      leave,
      formatSize,
    };
  },
});
