<template>
  <div
    class="card mb-7"
    data-bs-toggle="card-collapse"
    data-bs-target="#collapse"
    @mouseover="collapse = true"
    @mouseleave="collapse = false"
  >
    <!-- Badge -->
    <template v-if="data.status">
      <div class="badge badge-white card-badge card-badge-left text-uppercase">{{ data.status }}</div>
    </template>

    <template v-if="variations">
      <!-- Image -->
      <flickity ref="flickity" :options="options">
        <div class="card-img" id="productOneImg">
          <router-link
            class="d-block w-100"
            v-for="variation in variations"
            :key="variation.id"
            :to="{ name: 'ProductDetails', params: { productSlug: variation.slug } }"
          >
            <img class="card-img-top" :src="variation.image" :alt="variation.name" />
          </router-link>
        </div>
      </flickity>
    </template>
    <template v-else>
      <!-- Image -->
      <flickity ref="flickity" :options="options">
        <div class="card-img">
          <router-link class="d-block w-100" :to="{ name: 'ProductDetails', params: { productSlug: data.slug } }">
            <img class="card-img-top" :src="data.thumbnail" :alt="data.name" />
          </router-link>
        </div>
      </flickity>
    </template>
    <!-- Collapse -->
    <div class="card-collapse-parent">
      <!-- Body -->
      <div class="card-body px-0 pb-0 bg-white">
        <div class="row no-gutters">
          <div class="col">
            <!-- Title -->
            <router-link
              class="d-block font-weight-bold text-body"
              :to="{ name: 'ProductDetails', params: { productSlug: data.slug } }"
              >{{ data.name }}</router-link
            >

            <!-- Category -->
            <a class="font-size-xs text-muted" to="/">{{ data.category.name }}</a>
          </div>
          <div class="col-auto">
            <!-- Price -->
            <div class="font-size-sm font-weight-bold text-muted">${{ data.price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, toRefs, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Flickity from './Flickity.vue';

export default defineComponent({
  name: 'Card',
  components: {
    Flickity,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    variations: {
      type: Array,
      required: false,
    },
  },
  setup(props) {
    const { data } = toRefs(props);

    const router = useRouter();

    const route = useRoute();

    const collapse = ref(false);

    const options = reactive({
      prevNextButtons: false,
      pageDots: false,
      draggable: false,
      imagesLoaded: true,
      setGallerySize: true,
      adaptiveHeight: true,
      initialIndex: 0,
    });

    function goToSlug(slug) {
      router.push({ path: `/products/${slug}` });
    }

    function formatSize(size) {
      if (size.isInteger) {
        return size;
      } else {
        return size[0];
      }
    }

    const sizeAttribute = computed(() => {
      return data.value.attributes.filter((attribute) => attribute.category === 'Size');
    });

    const colorAttribute = computed(() => {
      const color: Array<any> = [];

      //TODO: select radio element that matches the color attribute in data.value.attributes

      /*for (const attribute of data.value.attributes) {
        if (attribute.category === 'Color') {
          color.push(attribute);
        }
      }*/

      for (const alternative of data.value.alternatives) {
        for (const attribute of alternative.attributes) {
          if (attribute.category === 'Color') {
            color.push(attribute);
          }
        }
      }

      return color;
    });

    function enter() {
      collapse.value = true;

      let cardCollapse = document.querySelector('.card-collapse');

      if (cardCollapse) {
        cardCollapse.classList.replace('collapse', 'collapsing');

        let isCollapsing = cardCollapse.classList.contains('collapsing');

        if (isCollapsing) {
          setTimeout(() => {
            if (cardCollapse) {
              cardCollapse.classList.replace('collapsing', 'collapse');
              cardCollapse.classList.add('show');
            }
          }, 100);
        } else {
          cardCollapse.classList.add('show');
        }
      }
    }

    function leave() {
      collapse.value = false;

      let cardCollapse = document.querySelector('.card-collapse');

      if (cardCollapse) {
        cardCollapse.classList.replace('collapse', 'collapsing');

        let isCollapsing = cardCollapse.classList.contains('collapsing');

        if (isCollapsing) {
          setTimeout(() => {
            if (cardCollapse) {
              cardCollapse.classList.replace('collapsing', 'collapse');
              cardCollapse.classList.remove('show');
            }
          }, 350);
        } else {
          cardCollapse.classList.remove('show');
        }
      }
    }

    return {
      collapse,
      sizeAttribute,
      colorAttribute,
      route,
      options,
      goToSlug,
      enter,
      leave,
      formatSize,
    };
  },
});
</script>

<style scoped>
.change {
  transform: translateY(-109px);
}
.card-img {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  overflow: hidden;
}
.card-img img {
  height: 350px;
}
.card-img-top {
  width: 100%;
}
</style>
