
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Breadcrumb',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
