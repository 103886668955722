<template>
  <nav class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Breadcrumb -->
          <ol class="breadcrumb mb-0 font-size-xs text-gray-400">
            <li class="breadcrumb-item">
              <router-link class="text-reset" to="/">Home</router-link>
            </li>
            <li class="breadcrumb-item active">{{ text }}</li>
          </ol>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Breadcrumb',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style></style>
