
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Breadcrumb from '../components/Breadcrumb.vue';
import TheCard from '../components/TheCard.vue';
import TheHeader from '../components/TheHeader.vue';

export default defineComponent({
  name: 'Shop',
  components: {
    Breadcrumb,
    TheHeader,
    TheCard,
  },
  setup() {
    const title = ref('All Products');
    const store = useStore();

    return {
      title,
      products: computed(() => store.state.products.products),
      totalProducts: computed(() => store.getters['products/productCount']),
    };
  },
});
