<template>
  <!-- Breadcrumb component-->
  <breadcrumb :text="this.title" />
  <section class="pt-7 pb-12">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!--Header Component-->
          <TheHeader :text="this.title" />
        </div>
      </div>
      <div class="row">
        <template v-for="product in products" :key="product.id">
          <div class="col-12 col-xs-12 col-sm-6 col-lg-4 col-xl-3">
            <!-- Card component-->
            <TheCard :data="product" />
          </div>
        </template>
      </div>
    </div>
    <!-- Load more section -->
    <div class="row">
      <div class="col-12">
        <!-- Progress -->
        <div class="row justify-content-center mt-7">
          <div class="col-12 text-center">
            <!-- Caption -->
            <p class="font-size-sm text-muted">Showing {{ totalProducts }} of {{ totalProducts }} products</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Breadcrumb from '../components/Breadcrumb.vue';
import TheCard from '../components/TheCard.vue';
import TheHeader from '../components/TheHeader.vue';

export default defineComponent({
  name: 'Shop',
  components: {
    Breadcrumb,
    TheHeader,
    TheCard,
  },
  setup() {
    const title = ref('All Products');
    const store = useStore();

    return {
      title,
      products: computed(() => store.state.products.products),
      totalProducts: computed(() => store.getters['products/productCount']),
    };
  },
});
</script>

<style></style>
